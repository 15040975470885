import axios from '~/lib/utils/axios_utils';
import Api from 'jh/api';

export const checkPhoneAvailability = (phone, cancelTokenCb) => {
  return axios
    .get(Api.buildUrl('/users/:phone/phone_exists').replace(':phone', phone), {
      cancelToken: new axios.CancelToken(cancelTokenCb),
    })
    .then(({ data }) => data.exists);
};
